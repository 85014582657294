import React from 'react';
import { Route, Switch, useRouteMatch, NavLink } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import StocksLog from './StocksLog';
// import EtfLog from './EtfLog';
// import Trades from './Trades';
// import IndicesLog from './IndicesLog';
const TrailSLVar1Analysis = () => {
    let { path, url } = useRouteMatch();
    console.log(path)
    return (
        <>
            <div className='nested_route_cntn'>
                <NavLink to={`${url}/stocks`} activeClassName="active-nested">Stocks</NavLink>
                {/* <NavLink to={`${url}/etf`} activeClassName="active-nested">ETF</NavLink>
                <NavLink to={`${url}/indices`} activeClassName="active-nested">Index</NavLink> */}
            </div>
            <Switch>
                <Route exact path={path}>
                    <Redirect to={`${path}/stocks`} />
                </Route>
                <Route exact path={`${path}/stocks`} component={StocksLog} />
                {/* <Route exact path={`${path}/etf`} component={EtfLog} />
                <Route exact path={`${path}/indices`} component={IndicesLog} /> */}
            </Switch>
        </>
    )
}

export default TrailSLVar1Analysis